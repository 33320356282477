<template>
  <container>
    <v-card outlined>
      <v-card-title>
        {{ $capitalize($tc('model.information_request_information_requests_title')) }}
      </v-card-title>

      <ScopeProvider scope="info_requests.read">
      <v-tabs v-model="show_all_requests">
        <v-tab :value="true">{{ $capitalize($tc('model.information_request_my_tab')) }}</v-tab>
        <v-tab :value="false">{{ $capitalize($tc('model.information_request_all_tab')) }}</v-tab>
      </v-tabs>
      </ScopeProvider>
      <v-divider></v-divider>

      <v-sheet v-if="show_all_requests" class="mx-5">
        <v-row>
          <v-col class="text-right">
            <v-menu :close-on-content-click="false" offset-y offset-x>
              <template v-slot:activator="{attrs, on}">
                <v-btn v-on="on" v-bind="attrs" text color="secondary">
                  {{ $capitalize($tc('model.information_request_filter_button')) }} <v-icon right>mdi-filter</v-icon>
                </v-btn>
              </template>

              <v-card width="450px">
                <v-form @submit.prevent="fetchAllRequest()">
                  <v-card-title>
                    {{ $capitalize($tc('model.information_request_advanced_filter')) }}
                    <v-spacer></v-spacer>
                    <v-btn @click="clearFilters()" color="primary" text>
                      <v-icon left>mdi-filter-off</v-icon> {{ $capitalize($tc('model.information_request_clean_button')) }}
                    </v-btn>
                  </v-card-title>

                  <v-card-text>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="filters.lead_number"
                            label="Lead"
                            hide-details
                            clearable 
                            autofocus
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <select-insurer
                            v-model="filters.insurer_id"
                            item-text="name"
                            item-value="id"
                            :return-object="false"
                            :label="$capitalize($tc('model.information_request_insurer_filter'))"
                            hide-details
                            hide-contact-detail
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-autocomplete
                            v-model="filters.manager_user_id"
                            :label="$capitalize($tc('model.information_request_responsible_field'))"
                            hide-details
                            clearable
                            :items="managers"
                            item-text="user.name"
                            item-value="id"
                            v-if="!filters.without_manager"
                          >
                          </v-autocomplete>
                          <v-checkbox v-model="filters.without_manager" @change="handleChangeWithoutManagerFilter" :label="$capitalize($tc('model.information_request_without_responsible'))"></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="filters.request_created_at"
                            type="date"
                            :label="$capitalize($tc('model.information_request_last_interaction'))"
                            hide-details
                            clearable
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-radio-group v-model="filters.closed" label="Status" mandatory>
                            <v-radio value="closed" :label="$capitalize($tc('model.information_request_closed'))"></v-radio>
                            <v-radio value="not_closed" :label="$capitalize($tc('model.information_request_opened'))"></v-radio>
                            <v-radio value="all" :label="$capitalize($tc('model.information_request_all_of_the_above'))"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn type="submit" :loading="loading_info_requests" color="primary">
                      <v-icon left>mdi-filter</v-icon>
                      {{ $capitalize($tc('model.information_request_filter_button')) }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-sheet>

      <v-data-table
        v-if="show_all_requests"
        :headers="headers"
        :items="info_requests"
        :server-items-length="totalItems"
        :loading="loading_info_requests"
        :options.sync="options"
        show-expand
        class="mt-3"
        :footer-props="{
          itemsPerPageOptions: [
            5,
            10,
            15,
            50
          ]
        }"
      >
        <template v-slot:header.lead_number>
          {{ $capitalize($tc("model.information_request_lead_column_table", 1)) }}
        </template>

        <template v-slot:header.insurer>
          {{ $capitalize($tc("model.information_request_insurer_colum_table", 1)) }}
        </template>

        <template v-slot:header.title>
          {{ $capitalize($tc("model.information_request_title_column_table", 1)) }}
        </template>

        <template v-slot:header.manager_name>
          {{ $capitalize($tc("model.information_request_responsible_column_table", 1)) }}
        </template>

        <template v-slot:header.last_interaction>
          {{ $capitalize($tc("model.information_request_last_interaction_column_table", 1)) }}
        </template>
        <template v-slot:header.comment_created_on>
          {{ $capitalize($tc("model.information_request_creation_date", 1)) }}
        </template>

        <template v-slot:item.flags="{ item }">
          <v-chip
            color="warning"
            outlined
            small
            v-if="item.closed_at == null"
          >{{ $capitalize($tc("model.information_request_opened_chip", 1)) }}</v-chip>
          <v-chip
            outlined
            small
            v-if="item.closed_at != null"
          >{{ $capitalize($tc("model.information_request_closed_chip", 1)) }}</v-chip>
          <v-tooltip bottom v-if="item.closed_at == null">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                dense
                icon
                small
                class="ml-1"
                :loading="closing.includes(item.id)"
                @click.stop="confirmCloseInfoRequest(item)"
              >
                <v-icon>
                  mdi-lock
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $capitalize($tc("model.information_request_mark_closed")) }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.closed_at != null">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                dense
                icon
                small
                class="ml-1"
                @click.stop="confirmReopenInfoRequest(item)"
                :loading="reopening.includes(item.id)"
              >
                <v-icon>
                  mdi-lock-open
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $capitalize($tc("model.information_request_mark_open")) }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.inbound_notification != null">
            <template v-slot:activator="{ on, attrs }"> 
              <v-icon
                v-bind="attrs"
                v-on="on"
                :color="getInboundNotificationStatus(item).status"
                class="ml-1"
              >{{ getInboundNotificationStatus(item).icon }}</v-icon>
            </template>
            <span>{{ getInboundNotificationStatus(item).text }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.insurer="{ item }">
          <span :title="item.insurer" class="d-inline-block text-truncate" style="width: 130px;">
            {{ item.insurer }}
          </span>
        </template>
        <template v-slot:item.description="{ item }">
          <span>{{ item.title || $capitalize($tc('model.comments_comment_untitled')) }}</span>
        </template>
        <template v-slot:item.manager_name="{ item }">
          <span v-if="!item.manager_user_id" class="grey--text">{{ $capitalize($tc('model.information_request_nobody')) }}</span>
          <span v-else>{{ item.manager_name }}</span>
        </template>
        <template v-slot:item.request_created_at="{ item }">
          <span v-if="item.request_created_at" :title="item.request_created_at">
            {{formatDateDuration(item.request_created_at)}}
          </span>
          <span v-else class="grey--text">
            {{ $capitalize($tc('model.information_request_not_identified')) }}
          </span>
        </template>
        <template v-slot:item.lead_number="{ item }">
          <router-link 
            :to="{ 
              name: 'lead_detail_comments', 
              params: { lead_number: item.lead_number } 
            }"
          >
            {{ item.lead_number }}
          </router-link >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            :to="{ 
              name: 'lead_detail_comments', 
              params: { lead_number: item.lead_number } 
            }"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="px-5 py-2">
            <span class="text-subtitle-2">{{ getSenderName(item) }}</span> <span class="grey--text ml-2">{{ formatDateTimeWithDuration(item.request_created_at) }}</span>
            <br>
            {{ item.description }}
          </td>
        </template>
      </v-data-table>
      <v-list v-else-if="my_info_requests.length > 0">
        <div v-for="(item, index) in my_info_requests" :key="item.service_number">
          <v-list-item 
            :to="{
              name: 'lead_detail_comments', 
              params: { lead_number: item.lead_number } 
            }"
            three-line
          >
            <v-list-item-content>
              <v-list-item-title>
                <span class="text-subtitle-1 font-weight-bold mr-2" v-if="item.created_by">{{ getSenderName(item) }}</span> 
                <v-chip small label color="primary">{{ item.lead_number }}</v-chip>
              </v-list-item-title>
              <v-list-item-subtitle class="text-caption pb-2">{{ item.insurer }}</v-list-item-subtitle>
              <v-list-item-subtitle class="text-subtitle-2">{{ item.title }}</v-list-item-subtitle>
              <v-list-item-subtitle v-html="prepareText(item.description)" />
            </v-list-item-content>
            <v-list-item-action>
              <span class="grey--text">{{ item.request_created_at ? formatDateDuration(item.request_created_at) : '' }}</span>
              <div>
                <v-chip
                  color="warning"
                  outlined
                  small
                  v-if="item.closed_at == null"
                > {{ $capitalize($tc('model.information_request_opened_chip')) }} </v-chip>
                <v-chip
                  outlined
                  small
                  v-if="item.closed_at != null"
                >Fechado</v-chip>
                <v-tooltip bottom v-if="item.closed_at == null">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      dense
                      icon
                      small
                      class="ml-1"
                      @click.stop.prevent="confirmCloseInfoRequest(item)"
                      :loading="closing.includes(item.id)"
                    >
                      <v-icon>
                        mdi-lock
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $capitalize($tc('model.information_request_mark_closed')) }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.closed_at != null">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      dense
                      icon
                      small
                      class="ml-1"
                      @click.stop.prevent="confirmReopenInfoRequest(item)"
                      :loading="reopening.includes(item.id)"
                    >
                      <v-icon>
                        mdi-lock-open
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $capitalize($tc('model.information_request_mark_open')) }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.inbound_notification != null">
                  <template v-slot:activator="{ on, attrs }"> 
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      :color="getInboundNotificationStatus(item).status"
                    >{{ getInboundNotificationStatus(item).icon }}</v-icon>
                  </template>
                  <span>{{ getInboundNotificationStatus(item).text }}</span>
                </v-tooltip>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index+1 < my_info_requests.length"></v-divider>
        </div>
      </v-list>
      <v-card-text v-else>
        <EmptyState :message="$capitalize($tc('model.information_request_message_empty_state'))" />
      </v-card-text>
    </v-card>
  </container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { formatDateTimeWithDuration, formatDateTime, formatDateDuration } from '@/tools/date';
import { 
  index as indexAllRequest,
  close as closeInfoRequest, 
  reopen as reopenInfoRequest, 
  getInboundNotificationStatus 
} from "@/services/info_requests";

import ScopeProvider from "@/components/ScopeProvider";
import EmptyState from "@/components/EmptyState";
import SelectInsurer from "@/components/SelectInsurer";

export default {
  components: { ScopeProvider, EmptyState, SelectInsurer },
  data: function() {
    return {
      expanded_comments: [],
      headers: [
        {
          text: '',
          value: 'flags',
          width: 180,
          sortable: false
        },
        {
          text: 'Lead',
          value: 'lead_number',
          width: 130
        },
        {
          text: 'Companhia',
          value: 'insurer',
          width: 130
        },
        {
          text: 'Título',
          value: 'title',
        },
        {
          text: 'Responsável',
          value: 'manager_name'
        },
        {
          text: 'Data de criação',
          value: 'request_created_at',
          width: 140
        },
        {
          text: '',
          value: 'actions',
          width: 75,
          sortable: false
        }
      ],

      // Show All
      show_all_requests: false,
      info_requests: [],
      loading_info_requests: false,
      options: {
        itemsPerPage: 10,
        sortBy: ["request_created_at"],
        sortDesc: []
      },
      totalItems: 0,
      search_all_requests: "",
      filters: {
        closed: 'not_closed',
      },
      enable_filters: [],
      managers: [],

      // Show Mine
      search_my_requests: "",

      closing: [],
      reopening: []
    };
  },
  methods: {
    getInboundNotificationStatus(info_request) {
      const inbound_notification = info_request.inbound_notification;
      return getInboundNotificationStatus(inbound_notification);
    },
    formatLongDate(date) {
      return formatDateTime(date);
    },
    handleChangeWithoutManagerFilter(value) {
      if(value) {
        this.filters.manager_user_id = null;
      }
    },
    clearFilters() {
      this.filters = {
        closed: 'not_closed',
      };
      this.fetchAllRequest();
    },
    prepareText(text) {
      if (text) {
        return text.replace(/(?:\r\n|\r|\n)/g, "<br />");
      }

      return this.$capitalize(this.$tc('model.information_request_no_comments_message'));
    },
    getSenderName(item) {
      return item.created_by;
    },
    formatDateTimeWithDuration(raw_date) {
      return formatDateTimeWithDuration(raw_date);
    },
    formatDateDuration(date) {
      return formatDateDuration(date);
    },
    async fetchAllRequest() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.loading_info_requests = true;
      const response = await indexAllRequest({
          ...this.filters,
          orderBy: sortBy[0] ? sortBy[0] : "",
          sortDesc: sortDesc[0] ? true : false,
          page,
          perPage: itemsPerPage
        });
      this.info_requests = response.data;
      this.totalItems = response.total;
      this.loading_info_requests = false;
      this.managers = response.metadata.managers;
    },
    confirmReopenInfoRequest(info_request) {
      this.$store.dispatch("confirm_dialog/confirm", {
        title: this.$capitalize(this.$tc('model.comments_reopen_info_request')),
        description: this.$capitalize(this.$tc('model.comments_order_reopen_no_action_message')),
        positive_action: () => this.reopenInfoRequest(info_request),
      }); 
    },
    async reopenInfoRequest(info_request) {
      this.reopening.push(info_request?.id);
      try {
        const response = await reopenInfoRequest(info_request?.id);

        this.$store.commit("sendMessage", {
          text: this.$capitalize(this.$tc('model.comments_info_request_reopened')),
          color: "green",
        });
        this.loadMyInfoRequests();
        this.fetchAllRequest();
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.$capitalize(this.$tc('model.comments_failed_reopen_info_request_alert')),
          color: "red",
        });
      } finally {
        this.reopening = this.reopening.filter(rec => rec != info_request?.id);
      }
    },
    confirmCloseInfoRequest(info_request) {
      this.$store.dispatch("confirm_dialog/confirm", {
        title: this.$capitalize(this.$tc('model.comments_close_info_request')) + '?',
        description: this.$capitalize(this.$tc('model.comments_order_closed_no_action_message')),
        positive_action: () => this.closeInfoRequest(info_request),
      }); 
    },
    async closeInfoRequest(info_request) {
      this.closing.push(info_request?.id);
      try {
        const response = await closeInfoRequest(info_request?.id);

        this.$store.commit("sendMessage", {
          text: this.$capitalize(this.$tc('model.comments_info_request_closed')),
          color: "green",
        });
        this.fetchAllRequest();
        this.loadMyInfoRequests();
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.$capitalize(this.$tc('model.comments_fail_closed_info_request_alert')),
          color: "red",
        });
      } finally {
        this.closing = this.closing.filter(rec => rec != info_request?.id);
      }
    },
    ...mapActions("info_requests", ["loadMyInfoRequests"])
  },
  computed: {
    filtered_my_info_requests() {
      const regex = new RegExp(this.search_my_requests ? this.search_my_requests : "", "g");
      return this.my_info_requests.filter(request =>
        request.title.match(regex) || request.description.match(regex) || request.lead_number.match(regex)
      );
    },
    ...mapState("info_requests", ["my_info_requests"])
  },
  watch: {
    options: {
      handler() {
        this.fetchAllRequest();
      },
      deep: true,
    },
    show_all_requests: function(value) {
      if(value == true) {
        this.loadMyInfoRequests();
      }
    }
  },
  created() {
    this.fetchAllRequest();
  }
}
</script>

<style>
.v-data-table tbody tr.v-data-table__expanded__content {
  -webkit-box-shadow: inset 0 2px 4px -3px rgb(50 50 50 / 75%), inset 0 -2px 4px -3px rgb(50 50 50 / 75%);
  box-shadow: inset 0 2px 4px -3px rgb(50 50 50 / 75%), inset 0 -2px 4px -3px rgb(50 50 50 / 75%);
}
</style>